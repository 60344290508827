import { FormattedMessage } from "@/i18n/i18n-client";
import { MyOrdersIcon } from "@/assets/Icons/MyOrdersIcon";
import { MyReviewsFilledIcon } from "@/assets/Icons/MyReviewsFilledIcon";
import { MyAccountIcon } from "@/assets/Icons/MyAccountIcon";
import { MyAddressesIcon } from "@/assets/Icons/MyAddressesIcon";
import { LogoutIcon } from "@/assets/Icons/LogoutIcon";

export enum mePageItemsEnum {
  MyOrders = "my-orders",
  MyReviews = "my-reviews",
  MyAccount = "my-account",
  MyAddresses = "my-addresses",
  LogOut = "log-out",
}

export const mePageItems = [
  {
    id: mePageItemsEnum.MyOrders,
    icon: <MyOrdersIcon />,
    title: <FormattedMessage defaultMessage="My Orders" id="xBOeUs" />,
    slug: mePageItemsEnum.MyOrders,
  },
  {
    id: mePageItemsEnum.MyReviews,
    icon: <MyReviewsFilledIcon />,
    title: <FormattedMessage defaultMessage="My Reviews" id="uGDrXD" />,
    slug: mePageItemsEnum.MyReviews,
  },
  {
    id: mePageItemsEnum.MyAccount,
    icon: <MyAccountIcon />,
    title: <FormattedMessage defaultMessage="My Account" id="2bGejb" />,
    slug: mePageItemsEnum.MyAccount,
  },
  {
    id: mePageItemsEnum.MyAddresses,
    icon: <MyAddressesIcon />,
    title: <FormattedMessage defaultMessage="My Addresses" id="pNzT4U" />,
    slug: mePageItemsEnum.MyAddresses,
  },
  {
    id: mePageItemsEnum.LogOut,
    icon: <LogoutIcon />,
    title: <FormattedMessage defaultMessage="Log Out" id="H0JBH6" />,
  },
];

export const filterMePageItems = (areReviewsActivated: boolean) =>
  mePageItems.filter((item) =>
    item.id === mePageItemsEnum.MyReviews ? areReviewsActivated : true
  );
