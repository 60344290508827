const MyReviewsFilledIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0002 0.25H2.00848C1.18348 0.25 0.507727 0.925 0.507727 1.75L0.500977 10.7493C0.500977 11.578 1.17223 12.25 2.00098 12.25H3.12523C3.33223 12.25 3.50023 12.418 3.50023 12.625V14.1895C3.50023 14.8577 4.30798 15.1922 4.78048 14.7197L7.03048 12.4697C7.17148 12.3287 7.36198 12.25 7.56073 12.25H14.0002C14.8252 12.25 15.5002 11.575 15.5002 10.75V1.75C15.5002 0.925 14.8252 0.25 14.0002 0.25ZM11.6152 5.4775L9.91348 6.95125L10.425 9.154C10.491 9.439 10.182 9.664 9.93073 9.5125L8.00023 8.347L6.06973 9.511C5.81848 9.6625 5.50948 9.4375 5.57623 9.15175L6.08773 6.95575L4.38523 5.4835C4.16398 5.2915 4.28173 4.92775 4.57423 4.90225L6.81898 4.71025L7.69573 2.63725C7.80973 2.368 8.19148 2.36725 8.30623 2.63725L9.18223 4.70425L11.4277 4.89625C11.7187 4.92175 11.8372 5.2855 11.6152 5.4775Z"
        fill="#5F738C"
      />
    </svg>
  );
};

export { MyReviewsFilledIcon };
