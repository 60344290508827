export const MyAddressesOutlinedIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75251 3.63086C9.43127 3.63092 9.1105 3.7514 8.86969 3.99219C8.38738 4.4747 8.3874 5.27725 8.86969 5.75977C9.35222 6.24224 10.1548 6.24246 10.6373 5.75977C10.9743 5.42279 10.9898 4.95671 10.856 4.52539C10.857 4.32657 10.779 4.13549 10.6392 3.99414L10.6372 3.99219L10.6352 3.99024C10.4907 3.84634 10.2911 3.83103 10.106 3.77344H10.104C9.98127 3.73542 9.88036 3.63084 9.75251 3.63086Z"
        fill="#667085"
      />
      <path
        d="M9.7525 0C7.30219 -1e-07 4.71363 1.88722 4.69782 4.97266C4.69585 4.9746 4.6939 4.97655 4.69196 4.97852V5C4.6939 5.00196 4.69585 5.00392 4.69782 5.00586C4.70624 6.38841 5.31797 7.69976 6.38141 8.58594L7.79352 9.76367C8.2497 10.1445 8.59175 10.6455 8.77985 11.209L9.04157 11.9922C9.09164 12.1411 9.1872 12.2705 9.31475 12.3622C9.44231 12.4538 9.59542 12.5031 9.7525 12.5031C9.90958 12.5031 10.0627 12.4538 10.1903 12.3622C10.3178 12.2705 10.4134 12.1411 10.4634 11.9922L10.7252 11.2109V11.2089C10.9133 10.6447 11.2549 10.1447 11.7115 9.76363L13.1255 8.58589C14.1823 7.70423 14.7909 6.40084 14.8052 5.02534C14.8087 5.01694 14.8119 5.00848 14.815 4.99995C14.815 4.99564 14.8131 4.99254 14.813 4.98823C14.813 4.98483 14.815 4.98186 14.815 4.97846C14.8136 4.87391 14.7903 4.7708 14.7466 4.67578C14.5566 1.7829 12.1155 0 9.7525 0ZM9.7525 1.5C11.4585 1.5 13.3059 2.72071 13.313 4.98828C13.31 5.93393 12.8913 6.8273 12.1646 7.43359L10.7505 8.61328C10.2884 8.99901 10.0356 9.55039 9.7525 10.0762C9.46951 9.55041 9.21695 8.99738 8.75446 8.61133L7.34039 7.43359C6.61303 6.82747 6.19691 5.93438 6.19391 4.98828C6.20018 2.72194 8.04634 1.5 9.7525 1.5Z"
        fill="#667085"
      />
      <path
        d="M3.75054 9.00391C2.44404 9.00391 1.30957 9.93336 1.05328 11.2148L0.0532786 16.2168C-0.284845 17.9033 1.03044 19.5078 2.75054 19.5078H16.7544C18.4745 19.5078 19.7898 17.9033 19.4517 16.2168L18.4517 11.2148C18.1954 9.9334 17.061 9.00391 15.7545 9.00391C15.5555 9.00391 15.3648 9.08292 15.2241 9.22358C15.0835 9.36423 15.0045 9.55499 15.0045 9.75391C15.0045 9.95282 15.0835 10.1436 15.2241 10.2842C15.3648 10.4249 15.5555 10.5039 15.7545 10.5039C16.3547 10.5039 16.8631 10.9204 16.981 11.5098L17.981 16.5117C18.1391 17.3002 17.5594 18.0078 16.7544 18.0078H2.75054C1.94559 18.0078 1.36591 17.3002 1.52398 16.5117L2.52398 11.5098C2.64185 10.9204 3.15025 10.5039 3.75054 10.5039C3.94946 10.5039 4.14022 10.4249 4.28087 10.2842C4.42153 10.1436 4.50054 9.95282 4.50054 9.75391C4.50054 9.55499 4.42153 9.36423 4.28087 9.22358C4.14022 9.08292 3.94946 9.00391 3.75054 9.00391Z"
        fill="#667085"
      />
    </svg>
  );
};
