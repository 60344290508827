export const MyAccountOutlinedIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74729 0C7.65806 2e-07 6.56816 0.41352 5.74143 1.24023C4.08798 2.89368 4.08798 5.59851 5.74143 7.25195C7.3949 8.90536 10.0997 8.90535 11.7531 7.25195C13.3342 5.67083 13.358 3.15146 11.9153 1.48633V1.48438C11.8778 1.39313 11.8227 1.31016 11.7531 1.24023C10.9264 0.413524 9.83652 -3e-07 8.74729 0ZM8.74729 1.49219C9.4495 1.49219 10.1524 1.76065 10.6926 2.30078C11.7728 3.38101 11.7728 5.11118 10.6926 6.19141C9.6123 7.27165 7.88225 7.27164 6.80198 6.19141C5.72175 5.11118 5.72175 3.38101 6.80198 2.30078C7.34211 1.76066 8.04508 1.49219 8.74729 1.49219Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.74732 10.0039C6.6019 10.0039 4.49212 10.5124 2.85278 11.4668C1.21344 12.4212 -0.00268555 13.9066 -0.00268555 15.7461V16.7461C-0.00268555 17.7039 0.789518 18.4961 1.74731 18.4961H15.7473C16.7051 18.4961 17.4973 17.7039 17.4973 16.7461V15.7461C17.4973 13.9066 16.2812 12.4212 14.6418 11.4668C13.0025 10.5124 10.8927 10.0039 8.74732 10.0039ZM8.74732 11.5039C10.6499 11.5039 12.5393 11.9785 13.8879 12.7637C15.2366 13.5488 15.9973 14.5596 15.9973 15.7461V16.7461C15.9973 16.8923 15.8935 16.9961 15.7473 16.9961H1.74731C1.60111 16.9961 1.49731 16.8923 1.49731 16.7461V15.7461C1.49731 14.5596 2.25803 13.5488 3.60669 12.7637C4.95535 11.9785 6.84474 11.5039 8.74732 11.5039Z"
        fill="#667085"
      />
    </svg>
  );
};
