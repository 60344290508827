export const MyOrdersOutlinedIcon = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21012 4.91126e-05C6.77185 0.00264911 6.33266 0.109053 5.93473 0.320362L1.43278 2.72271C0.859903 3.02823 0.50068 3.62818 0.505044 4.27739C0.505043 4.27804 0.505043 4.27869 0.505044 4.27934C0.509474 4.92857 0.875361 5.52457 1.45231 5.82231L13.5129 12.0489C14.3152 12.4626 15.2711 12.4561 16.0675 12.0313L20.5695 9.63091C21.1424 9.32541 21.5016 8.72351 21.4972 8.07427C21.4927 7.42513 21.125 6.82904 20.548 6.5313L8.48747 0.304737C8.08706 0.0981556 7.64839 -0.00255199 7.21012 4.91126e-05ZM7.79801 1.63872L19.8605 7.86333C19.9445 7.90669 19.9966 7.98939 19.9972 8.08403C19.9979 8.17858 19.9479 8.26219 19.8644 8.30669L15.3625 10.709C14.9992 10.9028 14.5662 10.9055 14.2004 10.7168L2.14176 4.48833C2.05775 4.44498 2.00569 4.36218 2.00504 4.26763C2.00441 4.17306 2.05443 4.08946 2.13786 4.04497L6.63786 1.64458C7.00078 1.45186 7.43285 1.45032 7.79801 1.63872Z"
        fill="#667085"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7922 4.9071e-05C14.3539 -0.00255093 13.9152 0.0981581 13.5148 0.304737L1.45231 6.5313C0.875361 6.82904 0.509478 7.42504 0.505044 8.07427C0.500684 8.72348 0.859877 9.32538 1.43278 9.63091L5.93473 12.0313C6.73119 12.4561 7.68703 12.4626 8.48942 12.0489L20.548 5.82231C21.125 5.52457 21.4927 4.92848 21.4972 4.27935V4.2774C21.5016 3.62815 21.1424 3.02821 20.5695 2.72271L16.0675 0.320362C15.6696 0.109055 15.2304 0.00265007 14.7922 4.9071e-05ZM14.2023 1.63872C14.5675 1.45032 14.9995 1.45185 15.3625 1.64458L19.8644 4.04497C19.9479 4.08949 19.9979 4.17309 19.9972 4.26763C19.9966 4.36227 19.9445 4.44497 19.8605 4.48833L7.80192 10.7168C7.43605 10.9055 7.00307 10.9028 6.63981 10.709L2.13786 8.30669C2.05446 8.26221 2.00441 8.17861 2.00504 8.08403C2.00569 7.98947 2.05775 7.90669 2.14176 7.86333L14.2023 1.63872Z"
        fill="#667085"
      />
      <path
        d="M3.9035 9.3418C3.70459 9.3418 3.51383 9.42081 3.37317 9.56147C3.23252 9.70212 3.1535 9.89288 3.1535 10.0918V14.0312C3.1535 15.0458 3.71507 15.9796 4.61053 16.457L9.7121 19.1816C10.5203 19.6127 11.4918 19.6127 12.3 19.1816H12.302L17.4036 16.457C18.2991 15.9796 18.8586 15.0458 18.8586 14.0312V10.0918C18.8586 9.89288 18.7796 9.70212 18.639 9.56147C18.4983 9.42081 18.3075 9.3418 18.1086 9.3418C17.9097 9.3418 17.719 9.42081 17.5783 9.56147C17.4376 9.70212 17.3586 9.89288 17.3586 10.0918V14.0312C17.3586 14.4947 17.1054 14.9168 16.6965 15.1348L11.595 17.8594C11.227 18.0556 10.7874 18.055 10.4192 17.8594H10.4172L5.31561 15.1348C4.90676 14.9168 4.6535 14.4947 4.6535 14.0312V10.0918C4.6535 9.89288 4.57449 9.70212 4.43383 9.56147C4.29318 9.42081 4.10242 9.3418 3.9035 9.3418Z"
        fill="#667085"
      />
      <path
        d="M11.0012 9.15625C10.8022 9.15625 10.6115 9.23527 10.4708 9.37592C10.3302 9.51657 10.2512 9.70734 10.2512 9.90625V18.7559C10.2512 18.9548 10.3302 19.1455 10.4708 19.2862C10.6115 19.4268 10.8022 19.5059 11.0012 19.5059C11.2001 19.5059 11.3908 19.4268 11.5315 19.2862C11.6721 19.1455 11.7512 18.9548 11.7512 18.7559V9.90625C11.7512 9.70734 11.6721 9.51657 11.5315 9.37592C11.3908 9.23527 11.2001 9.15625 11.0012 9.15625Z"
        fill="#667085"
      />
    </svg>
  );
};
