import { FormattedMessage } from "@/i18n/i18n-client";
import { MyAccountOutlinedIcon } from "@/assets/Icons/MyAccountOutlinedIcon";
import { MyOrdersOutlinedIcon } from "@/assets/Icons/MyOrdersOutlinedIcon";
import { MyAddressesOutlinedIcon } from "@/assets/Icons/MyAddressesOutlinedIcon";
import { MyReviewsOutlinedIcon } from "@/assets/Icons/MyReviewsOutlinedIcon";
import { LogoutIcon } from "@/assets/Icons/LogoutIcon";

export enum mePageItemsEnum {
  MyOrders = "my-orders",
  MyReviews = "my-reviews",
  MyAccount = "my-account",
  MyAddresses = "my-addresses",
  LogOut = "log-out",
}

export const mePageItems = [
  {
    id: mePageItemsEnum.MyAccount,
    icon: <MyAccountOutlinedIcon />,
    title: <FormattedMessage defaultMessage="My Account" id="2bGejb" />,
    slug: mePageItemsEnum.MyAccount,
  },
  {
    id: mePageItemsEnum.MyOrders,
    icon: <MyOrdersOutlinedIcon />,
    title: <FormattedMessage defaultMessage="My Orders" id="xBOeUs" />,
    slug: mePageItemsEnum.MyOrders,
  },
  {
    id: mePageItemsEnum.MyReviews,
    icon: <MyReviewsOutlinedIcon />,
    title: <FormattedMessage defaultMessage="My Reviews" id="uGDrXD" />,
    slug: mePageItemsEnum.MyReviews,
  },

  {
    id: mePageItemsEnum.MyAddresses,
    icon: <MyAddressesOutlinedIcon />,
    title: <FormattedMessage defaultMessage="My Addresses" id="pNzT4U" />,
    slug: mePageItemsEnum.MyAddresses,
  },
  {
    id: mePageItemsEnum.LogOut,
    icon: <LogoutIcon />,
    title: <FormattedMessage defaultMessage="Log Out" id="H0JBH6" />,
  },
];

export const filterMePageItems = (areReviewsActivated: boolean) =>
  mePageItems.filter((item) =>
    item.id === mePageItemsEnum.MyReviews ? areReviewsActivated : true
  );
