const MyAddressesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0003 3C9.01766 3 6.60026 5.43019 6.60026 8.42857C6.60026 12.3064 12.0003 18.381 12.0003 18.381C12.0003 18.381 17.4003 12.3064 17.4003 8.42857C17.4003 5.43019 14.9829 3 12.0003 3ZM11.9999 6.49007C13.0655 6.49007 13.9283 7.35736 13.9283 8.4286C13.9283 9.49893 13.0646 10.3671 11.9999 10.3671C10.9352 10.3671 10.0716 9.49983 10.0716 8.4286C10.0716 7.35736 10.9343 6.49007 11.9999 6.49007ZM3 22L5.5207 15.6667H7.66875C8.10525 16.328 8.54348 16.9406 8.94668 17.4762H6.73711L5.65781 20.1905H18.3422L17.2629 17.4762H15.0533C15.4565 16.9406 15.8948 16.328 16.3313 15.6667H18.4793L21 22H3Z"
        fill="#5F738C"
      />
    </svg>
  );
};

export { MyAddressesIcon };
