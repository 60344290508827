import React from "react";

export const LogoutSimpleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5C4.73478 5 4.48043 5.10536 4.29289 5.29289C4.10536 5.48043 4 5.73478 4 6V18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19C5.26522 19 5.51957 18.8946 5.70711 18.7071C5.89464 18.5196 6 18.2652 6 18V6C6 5.73478 5.89464 5.48043 5.70711 5.29289C5.51957 5.10536 5.26522 5 5 5ZM15.293 14.293C15.1108 14.4816 15.01 14.7342 15.0123 14.9964C15.0146 15.2586 15.1198 15.5094 15.3052 15.6948C15.4906 15.8802 15.7414 15.9854 16.0036 15.9877C16.2658 15.99 16.5184 15.8892 16.707 15.707L19.707 12.707C19.8945 12.5195 19.9998 12.2652 19.9998 12C19.9998 11.7348 19.8945 11.4805 19.707 11.293L16.707 8.293C16.6148 8.19749 16.5044 8.12131 16.3824 8.0689C16.2604 8.01649 16.1292 7.9889 15.9964 7.98775C15.8636 7.9866 15.7319 8.0119 15.609 8.06218C15.4861 8.11246 15.3745 8.18671 15.2806 8.2806C15.1867 8.3745 15.1125 8.48615 15.0622 8.60905C15.0119 8.73194 14.9866 8.86362 14.9877 8.9964C14.9889 9.12918 15.0165 9.2604 15.0689 9.3824C15.1213 9.50441 15.1975 9.61475 15.293 9.707L16.586 11H9C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12C8 12.2652 8.10536 12.5196 8.29289 12.7071C8.48043 12.8946 8.73478 13 9 13H16.586L15.293 14.293Z"
        fill="#CC0905"
      />
    </svg>
  );
};
